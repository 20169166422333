import {RouteMatch, RouteObject} from 'react-router-dom'

import AboutPage from './pages/About'
import BenefitsPage from './pages/Benefits'
import CareersPage from './pages/Careers'
import ContactPage from './pages/Contact'
import DEIPage from './pages/DEI'
import ExtractPage from './pages/Extract'
import HomePage from './pages/Home'
import ImpactPage from './pages/Impact'
import LinkPage from './pages/Link'
import NerdPage from './pages/Nerd'
import ResearchPage from './pages/Research'
import PrivacyPage from './pages/Privacy'
import ScribePage from './pages/Scribe'
import SolutionsPage from './pages/Solutions'
import TeamPage from './pages/Team'
import TermsOfServicePage from './pages/TermsOfService'
import TermsOfUsePage from './pages/TermsOfUse'
import WERPage from './pages/Scribe/WERPage'
import ThanksPage from './pages/Thanks'

export type EnhancedRouteObject = RouteObject & {
  redirectTo?: string
  status?: number
}

export interface EnhancedRouteMatch extends RouteMatch {
  route: EnhancedRouteObject
}

const routes: EnhancedRouteObject[] = [
  // pages
  {path: '/', element: <HomePage />},
  {path: '/about', element: <AboutPage />},
  {path: '/solutions', element: <SolutionsPage />},
  {path: '/extract', element: <ExtractPage />},
  {path: '/scribe', element: <ScribePage />},
  {path: '/scribe/wer-calculator', element: <WERPage />},
  {path: '/link', element: <LinkPage />},
  {path: '/nerd', element: <NerdPage />},
  {path: '/research', element: <ResearchPage />},
  {path: '/careers', element: <CareersPage />},
  {path: '/contact', element: <ContactPage />},
  {path: '/impact', element: <ImpactPage />},
  {path: '/dei', element: <DEIPage />},
  {path: '/benefits', element: <BenefitsPage />},
  {path: '/privacy', element: <PrivacyPage />},
  {path: '/team', element: <TeamPage />},
  {path: '/terms-of-use', element: <TermsOfUsePage />},
  {path: '/terms-of-service', element: <TermsOfServicePage />},
  {path: '/thank-you', element: <ThanksPage />},

  // redirects
  {path: '/breakdowns', redirectTo: '/scribe?utm_source=breakdowns', status: 302},
  {path: '/solutions/scribe', redirectTo: '/scribe', status: 301},
  {path: '/solutions/link', redirectTo: '/link', status: 301},
  {path: '/codex', redirectTo: '/solutions', status: 301},
  {path: '/capabilities', redirectTo: '/solutions', status: 301},
  {path: '/terms', redirectTo: '/terms-of-use', status: 301},

  // fallback
  // TODO: replace with a 404 page
  {path: '*', redirectTo: '/'},
]

export default routes
