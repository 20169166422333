import photoAdam from '../../assets/photos/testimonials/Adam_Norbury.png'
import photoAdity from '../../assets/photos/testimonials/Adity_Dokania.png'
import photoAriana from '../../assets/photos/testimonials/Ariana.jpg'
import photoCalla from '../../assets/photos/testimonials/Calla.jpg'
import photoDava from '../../assets/photos/testimonials/Dava.jpg'
import photoFred from '../../assets/photos/testimonials/Fred.jpg'
import photoGuillaume from '../../assets/photos/testimonials/Guillaume.jpg'
import photoHazel from '../../assets/photos/testimonials/Hazel.jpg'
import photoJilin from '../../assets/photos/testimonials/Jilin.jpg'
import photoJoey from '../../assets/photos/testimonials/Joey.jpg'
import photoJustin from '../../assets/photos/testimonials/Justin.jpg'
import photoNabeel from '../../assets/photos/testimonials/Nabeel.jpg'
import photoSelene from '../../assets/photos/testimonials/Selene.jpg'
import photoZiyi from '../../assets/photos/testimonials/Ziyi.jpg'
import photoShirley from '../../assets/photos/testimonials/Shirley.png'
import photoDavid from '../../assets/photos/testimonials/David.png'
import photoRayshard from '../../assets/photos/testimonials/Rayshard.png'
import photoBryan from '../../assets/photos/testimonials/Bryan.png'
import photoTarig from '../../assets/photos/testimonials/Tarig.png'

export type Team =
  | 'applications'
  | 'infrastructure'
  | 'machine-learning'
  | 'product'
  | 'product-design'
  | 'people'

export interface Testimonial {
  photo: string
  name: string
  team?: Team
  title?: React.ReactNode
  quote: React.ReactNode
}

const testimonialData: Testimonial[] = [
  {
    photo: photoSelene,
    name: 'Selene',
    title: 'Senior Software Engineer',
    team: 'applications',
    quote:
      'I love using Kensho‘s generous donation matching program each December. It is amazing to support causes I care about and have my impact tripled by Kensho!',
  },
  {
    photo: photoNabeel,
    name: 'Nabeel',
    title: 'Senior Software Engineer',
    team: 'applications',
    quote:
      'What makes Kensho special for me is the unique combination of genuine people, an authentic culture, and work that is meaningful and impactful.',
  },
  {
    photo: photoJoey,
    name: 'Joey',
    title: 'Senior Software Engineer',
    team: 'applications',
    quote:
      'There is such a variety of interesting and motivating projects at Kensho and flexibility to focus in to where you can make the most impact. Coupling that with an environment where open communication and strong feedback are encouraged feels very enabling.',
  },
  {
    photo: photoZiyi,
    name: 'Ziyi',
    title: 'Senior Infrastructure Engineer',
    team: 'infrastructure',
    quote:
      'The sense of family makes Kensho a great place to learn, live and work. At any point, if I have any kinds of questions (work-related or not), I always have support from honest and thoughtful coworkers.',
  },
  {
    photo: photoCalla,
    name: 'Calla',
    title: 'Software Engineer',
    team: 'applications',
    quote:
      'The core of what makes Kensho a wonderful place to work is the care they show for their employees. Schedule flexibility, benefits and extra resources, growth opportunities, and team dynamics are all structured to help individuals succeed not just in work but in life.',
  },
  {
    photo: photoAdam,
    name: 'Adam',
    title: 'Product Designer',
    team: 'product-design',
    quote:
      "Work-life balance at Kensho is perfect for me. You're given a lot of autonomy in work schedule and location. I have two small children and the rest of my family live in the UK. Working at Kensho, I'm solving really cool and interesting problems AND I get time to spend with all my family.",
  },
  {
    photo: photoAdity,
    name: 'Adity',
    title: 'Director, Infrastructure',
    team: 'infrastructure',
    quote:
      'Kenshins know how to keep the work environment fun and lively. In the past we have had multiple social activities, keeping in mind the diverse set of employees. We have hosted ping pong tournaments, TGITs, bowling, kayaking, board games, poker & trivia nights to name a few.',
  },
  {
    photo: photoRayshard,
    name: 'Rayshard',
    title: 'Infrastructure Engineer',
    team: 'infrastructure',
    quote:
      'At Kensho, the sky’s the limit for what there is to learn and do. I feel engaged with my work and connect with the people and company culture easily. I’m also slowly honing my inner barista skills with our espresso machines.',
  },
  {
    photo: photoFred,
    name: 'Fred',
    title: 'Senior Software Engineer',
    team: 'applications',
    quote:
      'A beautiful blend of the financial and networking resources of a large company and the creative autonomy of a startup - Kensho is a phenomenal place to build software and careers. Here you will find dynamic teams with the experience to feel success and the vigor to fuel innovation.  Here you will participate in the exploration and creation of products that can truly bring about change.',
  },
  {
    photo: photoDava,
    name: 'Dava',
    title: 'Executive Support and Facilities Manager',
    team: 'people',
    quote:
      'Kensho is my home away from home! I’ve never worked for a company that genuinely cares as much about all of their employees’ wellbeing, from providing a very flexible work-life balance and celebrating successes both big and small. Not to mention you get to work alongside so many amazing and smart individuals who are always willing to lend an ear (or a hand!)',
  },
  {
    photo: photoAriana,
    name: 'Ariana',
    title: 'Recruiter',
    team: 'people',
    quote:
      'I’m excited to continue focusing on our elite candidate interview experience while working alongside a fantastic team! I love that I am able to engage externally with candidates and jazz them about the idea of joining us. The most unique piece about working here is that Kensho truly provides excellent support and resources for all employees.',
  },
  {
    photo: photoBryan,
    name: 'Bryan',
    title: 'Head of Recruiting',
    team: 'people',
    quote:
      'One of the best parts about Kensho is the ability for a person to elevate themselves into new roles and to continuously learn new things. Being a small and fast paced innovation company allows our employees to constantly take on new projects, volunteer to be parts of new teams, and expand your breadth of knowledge in different spaces.',
  },
  {
    photo: photoTarig,
    name: 'Tarig',
    title: 'Product Manager',
    team: 'product',
    quote:
      'I love being a part of an organization and team that is directly taking steps in transforming the way people do work in the Business and Finance space. It’s fulfilling to be able to own and drive products that are significantly impacting our customers’ daily workflows.',
  },
  {
    photo: photoDavid,
    name: 'David',
    title: 'Product Manager',
    team: 'product',
    quote:
      'To me, what makes Kensho so compelling is that it maintains its dynamic startup-like environment with engaging and thoughtful people working on products they can directly impact, but it is also able to leverage the incredible resources and data of its parent company, S&P Global.',
  },
  {
    photo: photoHazel,
    name: 'Hazel',
    title: 'Product Designer',
    team: 'product-design',
    quote:
      'We have a unique structure at Kensho where our timelines are reasonable. This allows designers to have appropriate time for proper analysis and exploration, resulting in thoughtful solutions.',
  },
  {
    photo: photoShirley,
    name: 'Shirley',
    title: 'Product Designer',
    team: 'product-design',
    quote:
      'Product design at Kensho sits at the intersection of cutting-edge ML/NLP, business opportunities, domain knowledge, and traditional engineering work.  We are translators between technology, business, and humanity.  As for what matters to me, my favorite part of Kensho is a culture of learning and curiosity, which enables us to reshape the future of the financial landscape together.',
  },
  {
    photo: photoJustin,
    name: 'Justin',
    title: 'Senior Software Engineer',
    team: 'machine-learning',
    quote:
      'Kensho strikes the right balance in many areas for me: the stability of a public company with the autonomy of a startup, cutting-edge projects with an excellent work-life balance, and growth opportunities abound.',
  },
  {
    photo: photoJilin,
    name: 'Jilin',
    title: 'Applied Scientist',
    team: 'machine-learning',
    quote:
      'Everyone at Kensho is so friendly and nice, which makes Kensho like another family of mine. It is enjoyable to work with talented coworkers and managers who really care about my personal development. Great work-life-balance, and countless perks. I‘m impressed that how much I have grown only one year after joining Kensho. One can always learn more from the team, and make a real impact on company and the world.',
  },
  {
    photo: photoGuillaume,
    name: 'Guillaume',
    title: 'Applied Scientist',
    team: 'machine-learning',
    quote:
      'Kensho does its utmost to set you up for success, by providing flexibility and resources without ignoring work life balance. Just name a project you want to work on, tech you wish to learn about, or career path you aim for, Kensho will make it happen!',
  },
]

export default testimonialData
