import {css} from '@emotion/react'

import Badge from '../../Badge'

export interface NavLink {
  isGroup?: false
  label: string
  to?: string
  newTab?: boolean
  rightIcon?: React.ReactNode
}

export interface NavGroup {
  isGroup: true
  link: NavLink
  sublinks: NavLink[]
}

export type NavItem = NavGroup | NavLink

const badgeCss = css`
  margin-left: 8px;
`

export const navigation: NavItem[] = [
  {
    isGroup: true,
    link: {to: '/solutions', label: 'Solutions'},
    sublinks: [
      {to: '/solutions', label: 'All Solutions'},
      {to: '/scribe', label: 'Scribe'},
      {to: '/nerd', label: 'NERD'},
      {to: '/extract', label: 'Extract'},
      {to: '/link', label: 'Link'},
      {to: 'https://datasets.kensho.com', label: 'Datasets'},
    ],
  },
  {
    isGroup: true,
    link: {to: '/about', label: 'About'},
    sublinks: [
      {to: '/about', label: 'Who we are'},
      {to: '/research', label: 'Research'},
    ],
  },
  {
    isGroup: true,
    link: {to: '/careers', label: 'Life at Kensho'},
    sublinks: [
      {
        to: '/careers#available-positions',
        label: 'Available Positions',
      },
      {to: '/careers', label: 'Why Join Us?'},
      {to: '/dei', label: 'DEI'},
      {to: '/benefits', label: 'Benefits'},
      {to: '/impact', label: 'Impact'},
      {to: '/team', label: 'Team'},
    ],
  },
  {to: 'https://blog.kensho.com', label: 'Blog'},
  {
    to: 'https://benchmarks.kensho.com',
    label: 'S&P AI Benchmarks',
    rightIcon: <Badge css={badgeCss} label="Beta" />,
  },
]

export const selfServiceNavLink: NavLink = {
  to: 'https://services.kensho.com/users/login',
  label: 'Sign In',
}

export const freeTrialNavLink: NavLink = {
  to: 'https://services.kensho.com/free-trial',
  label: 'Free Trial',
}

export const contactUsNavLink: NavLink = {
  to: '/contact',
  label: 'Contact Us',
}
