import {css} from '@emotion/react'
import {IconChevronRight} from '@kensho/icons'

import {AnimatedContent} from '../../components/content'
import ButtonLink from '../../components/Button/ButtonLink'
import {BREAKPOINT_XLARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {URLS} from '../../constants'

import ProductCard from './ProductCard'

const gridCss = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;

  @media (max-width: ${BREAKPOINT_XLARGE}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    grid-template-columns: 1fr;
  }
`

const contentCss = css`
  height: 100%;
`

const buttonCardCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const buttonCss = css`
  width: 100%;
`

export default function ProductCardGrid(): JSX.Element {
  return (
    <div css={gridCss}>
      {/* Product cards */}
      <AnimatedContent css={contentCss} innerCss={contentCss}>
        <ProductCard
          description="Transcribe Speech to Text"
          productName="Scribe"
          bodyText="Transcribe complex financial and business audio into text, quickly and accurately."
          features={[
            '25% accuracy improvement over other transcription services.',
            'Real-time transcription.',
            '99% accuracy in 6 hours with professional review.',
          ]}
          freeTrialLink={`${URLS.FREE_TRIAL}/scribe`}
          learnMoreLink="/scribe"
        />
      </AnimatedContent>
      <AnimatedContent css={contentCss} innerCss={contentCss}>
        <ProductCard
          description="Identify Entities in Text"
          productName="NERD"
          bodyText="Identify companies, organizations, people, places and events in text and connect them to rich knowledge bases."
          features={[
            'Enable smart search and text filtering.',
            'Unlock deep analysis and insights with new connections.',
            'Reduce manual data processing.',
          ]}
          freeTrialLink={`${URLS.FREE_TRIAL}/nerd`}
          learnMoreLink="/nerd"
        />
      </AnimatedContent>
      <AnimatedContent css={contentCss} innerCss={contentCss}>
        <ProductCard
          description="Extract Data from PDF Documents"
          productName="Extract"
          bodyText="Make PDF documents easier to work with, search through, and structure."
          features={[
            'Convert PDFs into a machine-readable format.',
            'Structure data for downstream analytics.',
            'Flatten and standardize information in tables.',
          ]}
          freeTrialLink={`${URLS.FREE_TRIAL}/extract`}
          learnMoreLink="/extract"
        />
      </AnimatedContent>
      <AnimatedContent css={contentCss} innerCss={contentCss}>
        <ProductCard
          description="Map Company Entities to External Databases"
          productName="Link"
          bodyText="Connect messy company data to unique S&P Global IDs."
          features={[
            'Clean, enrich and deduplicate your database.',
            'No need to spend time training an ML model.',
            'Quickly spot the best results with match scores.',
          ]}
          freeTrialLink={`${URLS.FREE_TRIAL}/link`}
          learnMoreLink="/link"
        />
      </AnimatedContent>
      <AnimatedContent css={contentCss} innerCss={contentCss}>
        <ProductCard
          description="Train your ML models"
          productName="Datasets"
          bodyText="Professionally produced, domain-specific collections of training data for machine learning development."
          features={[
            'Access the highest quality NLP training data on the market.',
            'Manually curated and reviewed by field experts.',
            'Free for academia and non-commercial use.',
          ]}
          learnMoreLink="https://datasets.kensho.com"
        />
      </AnimatedContent>
      <div css={buttonCardCss}>
        <AnimatedContent>
          <ButtonLink to="/solutions" css={buttonCss} variant="secondary" mode="light">
            View all solutions
            <IconChevronRight />
          </ButtonLink>
        </AnimatedContent>
      </div>
    </div>
  )
}
